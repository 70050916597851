<script setup>
  import logos from './assets/logos';
  const src = logos
  const BASE_URL = window.location.href
</script>

<template>
  <nav>

  </nav>
  <main class="px-1 d-flex justify-center mb-2">
    <section>
      <h1 class="pt-1 mb-1 main-title">Design sources</h1>

      <div v-for="logoTheme, i_th in src.content" :key="i_th" :style="{backgroundColor: logoTheme.previewBackground}" class="hk-theme-holder my-4">
        <h2 class="px-3 pt-3 my-0" :style="{color: logoTheme.previewForeground}">Theme '{{ logoTheme.name }}'</h2>
        <p class="px-3 my-1" :style="{color: logoTheme.previewForeground}">{{ logoTheme.desc }}</p>

        <div class="hk-theme-logo-container" :style="{color: logoTheme.previewForeground}">
          <div v-for="logo, i_lg in logoTheme.content" :key="i_lg" class="hk-logo-holder pa-1">
            <div class="hk-image-image-holder">
              <img 
                :src="`${BASE_URL}${src.contentDir}/${logoTheme.dir}/${logo.file}`"
                :alt="`${logo.title} logo`"
                :style="{color: logoTheme.previewForeground}"
              />
            </div>
            <div class="hk-image-text-holder pa-1">
              <h3 class="my-0 mb-1">{{ logo.title }}</h3>
              <p>{{ logo.file }}</p>
              <input
                :value="`${BASE_URL}${src.contentDir}/${logoTheme.dir}/${logo.file}`" readonly
                :style="{color: logoTheme.previewForeground}"
                :name="`${logo.file}_src_value`"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- TODO: theme swither -->
      <!-- TODO: array list render of imgs -->
      <!-- TODO: selected content modal  -->

    </section>
  </main>
  <HKuboFooter_v1 />
</template>

<script>
import HKuboFooter_v1 from '@/components/HKuboFooter_v1.vue';

export default {
  name: 'App',
  components: {
    HKuboFooter_v1,
  },
  mounted() {
    // console.log(process.env.VUE_APP_SEVER_URL);
  },
}
</script>

<style lang="scss">
@import '@/styles/hk-bootstrap.scss';
$error: rgb(253, 121, 79);

html {
  background-color: #272626;


  * {
    //border: 1px dotted rgb(121, 199, 252);
  }

  body {
    margin: 0;
    background-color: #272626 !important;
  }

  main {
    > section {
      width: 100%;
      max-width: 1400px;
    }
  }

  h1, h2, h3, h4, h5 {
    font-family: Syne, Arial;
  }

  h1 {
    font-weight: 900;
    font-size: 3rem;
    color: white;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.hk-theme-logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.hk-theme-holder {
  border-radius: 1.5em;
}

.hk-logo-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 1em;

  .hk-image-image-holder {
    width: min(360px, 70vw);
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hk-image-text-holder {
    width: min(360px, 70vw);
    box-sizing: border-box;
    border: 2px solid #494747;
    box-shadow: 0 0 1rem -0.4rem #555;
    border-radius: 0.7em;

    > * {
      white-space: normal;
    }

    h3 {
      font-family: Quicksand, Arial, Helvetica, sans-serif;
    }

    p {
      font-size: 0.7em;
      margin: 0.3rem 0;
    }

    input {
      width: 100%;
      box-sizing: border-box;
      padding: 0.3em 0.3em;
      background-color: transparent;
      text-overflow: ellipsis;

      border: 1px solid #646161;
      border-radius: 0.5em;
      margin-bottom: 0;
    }
  }

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    
    &::before {
      border-top: 2px solid $error;
      display: block;
      content: 'Image';
      padding: 0.5rem 0;
    }
    
    &::after {
      border-bottom: 2px solid $error;
      display: block;
      content: 'was not found...';
      padding: 0.5rem 0;
    }
  }
}
</style>
