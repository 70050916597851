<template>
  <footer>
    <section class="d-flex justify-center hk-footer-container py-3">
      <div class="d-flex justify-space-between flex-wrap">
        <div class="px-2 d-flex align-center">
          <!--
            <ul>
              <li>This footer in empty</li>
              <li>For now</li>
            </ul>
          -->
          <p class="ma-0">Made with 🥐 by HKubo</p>
        </div>

        <div class="px-2 logo-text-holder">
          <p class="pt-1 pb-0 ma-0">2023</p>
          <a href="https://hkubo.sk" target="_blank">
            <img src="@/assets/hk-lg-small-light.png" class="py-1 ma-0">
          </a>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
footer {
  box-shadow: 0 0.7rem 1rem 1rem #161515;
  font-family: Manrope, Roboto, Arial;
  background-color: #1a1919;
  color: #bbb;

  * > {
    display: flex;
  }

  img {
    height: 0.7cm;
  }
}

.hk-footer-container {
  
  > div {
    width: 100%;
    max-width: 1000px;
  }

  .logo-text-holder {
    border-left: 2px solid #bbb;
  }
}
</style>
