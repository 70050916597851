const logo_list = {
  contentDir: 'content',
  content: [
    {
      name: 'Light',
      desc: 'primary content communication',
      dir: 'light',
      previewBackground: '#333131',
      previewForeground: '#eee',
      content: [
        {title: 'Large light badge+text', file: 'hk-lg-big-light.png'},
        {title: 'Large light badge', file: 'hk-lg-big-light-badge.png'},
        {title: 'Large light outline badge', file: 'hk-lg-big-light-badge-outl.png'},
        {title: 'Large light outline badge+text', file: 'hk-lg-big-light-outl.png'},
        {title: 'SVG Large light badge', file: 'hk-lg-big-light-badge.svg'},
        {title: 'Small light badge+text', file: 'hk-lg-small-light.png'},
        {title: 'Small light badge+text', file: 'hk-lg-small-light-badge.png'},
        {title: 'Small light outline badge', file: 'hk-lg-small-light-badge-outl.png'},
        {title: 'Small light outline badge+text', file: 'hk-lg-small-light-outl.png'},
        {title: 'SVG Small light badge', file: 'hk-lg-small-light-badge.svg'},
      ],
    },
    {
      name: 'Dark',
      desc: 'for light occasions',
      dir: 'dark',
      previewBackground: '#f1f0f0',
      previewForeground: '#333',
      content: [
        {title: 'Large dark badge+text', file: 'hk-lg-big-dark.png'},
        {title: 'Large dark badge', file: 'hk-lg-big-dark-badge.png'},
        {title: 'Large dark outline badge', file: 'hk-lg-big-dark-badge-outl.png'},
        {title: 'Large dark outline badge+text', file: 'hk-lg-big-dark-outl.png'},
        {title: 'SVG Large dark badge', file: 'hk-lg-big-dark-badge.svg'},
        {title: 'Small dark badge+text', file: 'hk-lg-small-dark.png'},
        {title: 'Small dark badge+text', file: 'hk-lg-small-dark-badge.png'},
        {title: 'Small dark outline badge', file: 'hk-lg-small-dark-badge-outl.png'},
        {title: 'Small dark outline badge+text', file: 'hk-lg-small-dark-outl.png'},
        {title: 'SVG Small dark badge', file: 'hk-lg-small-dark-badge.svg'},
      ],
    },
  ]
}

export default logo_list
